import React from 'react'
import styled from 'styled-components'
import Layout from "../components/Layout"
import AppSeo from "../components/AppSeo"
import { useForm } from 'react-hook-form'

import FormGroup from '@material-ui/core/FormGroup';
//import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from "@material-ui/styles/makeStyles";


function Projectassesment() {

  const useStyles = makeStyles({
    root: {
      width: 300
    }
  });
  const classes = useStyles();
  const { register, handleSubmit, watch } = useForm();
  const inputProps = {
    maxLength: '64'
  };
  const onSubmit = async ( data, e ) => {
    //console.log(data)
    //console.log(e.target)

    e.preventDefault()

    try{
      const response = await fetch('https://omeafbagk6.execute-api.eu-central-1.amazonaws.com/latest/api/project-assesment', {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        headers: { "Content-Type": "application/json" },
        redirect: "follow", // manual, *folslow, error
        referrer: "client", // no-referrer, *client
        body: JSON.stringify(data),
      })

      const answer = await response.json()

      if (answer.success) {
        alert(answer.message)
      }
      else {
        alert(answer.errors[0].msg)
      }
      
    }catch (err){
        alert('Error connecting to backend:', err)
    }

    e.target.reset()
  }

  //console.log(watch("webDevelopment")); // watch input value by passing the name of it

  return (
    <Layout>
      <AppSeo title="Project Assesment" />
      <PASection>
        <PAContainer>
          <PAHeader>PROJECT ASSESMENT</PAHeader>
          <PAParagraph>Please take a moment to fill this survey in order for me to get a better idea of your needs and make more accurate estimates for the project.</PAParagraph>
        </PAContainer>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate action="https://omeafbagk6.execute-api.eu-central-1.amazonaws.com/latest/api/"> {/* Add method="post" and action properties to setup where to send data */}
          <PASlice>
            <SliceLeft>
              <SliceLeftBG></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Services</FormHeader>
                <FormDesc>Select services you’d be interested of for your business.</FormDesc>
                <FormGroupCheck name="services">
                  <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormCheck type="checkbox" id="webdevelopment" name="webdevelopment" value="true" style={{color: '#0FB680'}} {...register("webDeveloment")}></FormCheck>
                        Website Development
                      </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="webdesign" name="webdesign" value="true" style={{color: '#0FB680'}} {...register("webDesign")}></FormCheck>
                      Web Design (UI/UX)
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="seoconsultation" name="seoconsultation" value="true" style={{color: '#0FB680'}} {...register("seoConsultation")}></FormCheck>
                      SEO Consultation
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="branding" name="branding" value="true" style={{color: '#0FB680'}} {...register("branding")}></FormCheck>
                      Branding
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="contentproduction" name="contentproduction" value="true" style={{color: '#0FB680'}} {...register("contentProduction")}></FormCheck>
                      Content Production
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="marketinganalytics" name="marketinganalytics" value="true" style={{color: '#0FB680'}} {...register("marketingAnalytics")}></FormCheck>
                      Marketing & Analytics
                    </FormSelectTitle>
                    </Grid>
                  </Grid>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG style={{backgroundColor: '#0FB680'}}></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Budget</FormHeader>
                <FormDesc>What is your estimated budget for this project?</FormDesc>
                <FormGroupCheck name="budget">
                <RadioGroup>
                  <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormRadio type="radio" id="onetotwo" name="onetotwo" value="OneToTwo" style={{color: '#0FB680'}} {...register("oneToTwo")}></FormRadio>
                        1000 - 2000€
                      </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormRadio type="radio" id="twotofour" name="twotofour" value="TwoToFour" style={{color: '#0FB680'}} {...register("twoToFour")}></FormRadio>
                      2000 - 4000€
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormRadio type="radio" id="fourtosix" name="fourtosix" value="FourToSix" style={{color: '#0FB680'}} {...register("fourToSix")}></FormRadio>
                      4000 - 6000€
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormRadio type="radio" id="sixtoeight" name="sixtoeight" value="SixToEight" style={{color: '#0FB680'}} {...register("sixToEight")}></FormRadio>
                      6000 - 8000€
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormRadio type="radio" id="tentotwenty" name="tentotwenty" value="TenToTwenty" style={{color: '#0FB680'}} {...register("tenToTwenty")}></FormRadio>
                      10 000 - 20 000€
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormRadio type="radio" id="twentytofifty" name="twentytofifty" value="TwentyToFifty" style={{color: '#0FB680'}} {...register("twentyToFifty")}></FormRadio>
                      20 000 - 50 000€
                    </FormSelectTitle>
                    </Grid>
                  </Grid>
                  </RadioGroup>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG style={{backgroundColor: '#0FB680'}}></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG ></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Timeframe</FormHeader>
                <FormDesc>When would you like the work to be done?</FormDesc>
                <FormGroupCheck name="timeframe">
                <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormTextField type="date" id="projectdate" name="projectdate" className={classes.root} style={{backgroundColor: '#d9d9d9'}} {...register("projectDate")}></FormTextField>
                      </FormSelectTitle>
                    </Grid>
                </Grid>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG style={{backgroundColor: '#0FB680'}}></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Goals</FormHeader>
                <FormDesc>What kind of goals are you trying to achieve with this project?</FormDesc>
                <FormGroupCheck name="goals">
                  <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormCheck type="checkbox" id="moresales" name="moresales" value="true" style={{color: '#0FB680'}} {...register("moreSales")}></FormCheck>
                        Get more sales
                      </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="reachaudience" name="reachaudience" value="true" style={{color: '#0FB680'}} {...register("reachAudience")}></FormCheck>
                      Reach targeted/wider audience
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="moreconversions" name="moreconversions" value="true" style={{color: '#0FB680'}} {...register("moreConversions")}></FormCheck>
                      Get more conversions
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="companyimage" name="companyimage" value="true" style={{color: '#0FB680'}} {...register("companyImage")}></FormCheck>
                      Improve company image
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="increaseauthority" name="increaseauthority" value="true" style={{color: '#0FB680'}} {...register("increaseAuthority")}></FormCheck>
                      Increase authority
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="onlineexperience" name="onlineexperience" value="true" style={{color: '#0FB680'}} {...register("onlineExperience")}></FormCheck>
                      Improve online experience
                    </FormSelectTitle>
                    </Grid>
                  </Grid>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG style={{backgroundColor: '#0FB680'}}></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Functionality <span style={{fontWeight: 300}}>(Only for website customers)</span></FormHeader>
                <FormDesc>Select special functionalities you’d like to have.</FormDesc>
                <FormGroupCheck name="functionality">
                  <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormCheck type="checkbox" id="contactform" name="contactform" value="true" style={{color: '#0FB680'}} {...register("contactForm")}></FormCheck>
                        Contact form / Forms
                      </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="ecommerce" name="ecommerce" value="true" style={{color: '#0FB680'}} {...register("eCommerce")}></FormCheck>
                      E-commerce
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="blog" name="blog" value="true" style={{color: '#0FB680'}} {...register("Blog")}></FormCheck>
                      Blog
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="displayvideo" name="displayvideo" value="true" style={{color: '#0FB680'}} {...register("displayVideo")}></FormCheck>
                      Display video
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="photogallery" name="photogallery" value="true" style={{color: '#0FB680'}} {...register("photoGallery")}></FormCheck>
                      Photo Gallery
                    </FormSelectTitle>
                    </Grid>
                    <Grid item xs={12} spacing={4} sm={6}>
                    <FormSelectTitle>
                      <FormCheck type="checkbox" id="search" name="search" value="true" style={{color: '#0FB680'}} {...register("Search")}></FormCheck>
                      Search
                    </FormSelectTitle>
                    </Grid>
                  </Grid>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG ></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Pages <span style={{fontWeight: 300}}>(Only for website customers)</span></FormHeader>
                <FormDesc>How many different pages do you need? <span style={{fontWeight: 300}}>For example: home, about, services, etc.</span></FormDesc>
                <FormGroupCheck name="pages">
                <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormTextField type="number" id="projectpages" name="projectpages" className={classes.root} style={{backgroundColor: '#d9d9d9'}} {...register("projectPages")}></FormTextField>
                      </FormSelectTitle>
                    </Grid>
                </Grid>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG ></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Hosting and domain <span style={{fontWeight: 300}}>(Only for website customers)</span></FormHeader>
                <FormDesc>Do you need a domain for this project? <span style={{fontWeight: 300}}>For example: www.nike.com</span> </FormDesc>
                <FormGroupCheck name="hosting">
                  <RadioGroup>
                    <Grid container spacing={0}>
                        <Grid item xs={12} spacing={4} sm={6}>
                          <FormSelectTitle>
                            <FormRadio type="radio" id="domainyes" name="domainyes" value="DomainYes" style={{color: '#0FB680'}} {...register("domainYes")}></FormRadio>
                            Yes
                          </FormSelectTitle>
                          <FormSelectTitle>
                            <FormRadio type="radio" id="domainno" name="domainno" value="DomainNo" style={{color: '#0FB680'}} {...register("domainNo")}></FormRadio>
                            No
                          </FormSelectTitle>
                        </Grid>
                    </Grid>
                  </RadioGroup>
                  <FormDesc>Do you need hosting for this project?</FormDesc>
                  <RadioGroup>
                    <Grid container spacing={0}>
                        <Grid item xs={12} spacing={4} sm={6}>
                          <FormSelectTitle>
                            <FormRadio type="radio" id="hostingyes" name="hostingyes" value="HostingYes" style={{color: '#0FB680'}} {...register("hostingYes")}></FormRadio>
                            Yes
                          </FormSelectTitle>
                          <FormSelectTitle>
                            <FormRadio type="radio" id="hostingno" name="hostingno" value="HostingNo" style={{color: '#0FB680'}} {...register("hostingNo")}></FormRadio>
                            No
                          </FormSelectTitle>
                        </Grid>
                    </Grid>
                  </RadioGroup>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG ></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Message</FormHeader>
                <FormDesc>Feel free to describe the project and the wanted outcomes here.</FormDesc>
                <FormGroupCheck name="other">
                <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormTextField type="text" id="other" name="other" label="Message" multiline={true} className={classes.root} style={{backgroundColor: '#d9d9d9'}} inputProps={{maxlength: '512'}} {...register("other")}></FormTextField>
                      </FormSelectTitle>
                    </Grid>
                </Grid>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG ></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
                <FormHeader>Contact details</FormHeader>
                <FormDesc>Please add your contact details here so that I can reach you.</FormDesc>
                <FormGroupCheck name="other">
                <Grid container spacing={0}>
                    <Grid item xs={12} spacing={4} sm={6}>
                      <FormSelectTitle>
                        <FormTextField type="text" id="name" name="name" label="Name*" className={classes.root}  style={{backgroundColor: '#d9d9d9'}} inputProps={inputProps} {...register("name", { required: true })}></FormTextField>
                      </FormSelectTitle>
                      <FormSelectTitle>
                        <FormTextField type="text" id="company" name="company" label="Company" className={classes.root} style={{backgroundColor: '#d9d9d9', marginTop: '1em'}} inputProps={inputProps} {...register("company")}></FormTextField>
                      </FormSelectTitle>
                      <FormSelectTitle>
                        <FormTextField type="email" id="email" name="email" label="Email*" className={classes.root} style={{backgroundColor: '#d9d9d9', marginTop: '1em'}} inputProps={inputProps} {...register("email", { required: true })}></FormTextField>
                      </FormSelectTitle>
                      <FormSelectTitle>
                        <FormTextField type="tel" id="phone" name="phone" label="Phone" className={classes.root} style={{backgroundColor: '#d9d9d9', marginTop: '1em'}} inputProps={{maxlength: '32'}} {...register("phone")}></FormTextField>
                      </FormSelectTitle>
                    </Grid>
                </Grid>
                </FormGroupCheck>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>
          <PASlice>
            <SliceLeft>
              <SliceLeftBG ></SliceLeftBG>
            </SliceLeft>
            <SliceMiddle>
              <FormInputButton type="submit" style={{color: '#fff', backgroundColor:'#0FB680'}}>Submit</FormInputButton>
            </SliceMiddle>
            <SliceRight>
              <SliceRightBG></SliceRightBG>
            </SliceRight>
          </PASlice>

        </Form>
      </PASection>
    </Layout>
  )
}

export default Projectassesment


const PASection = styled.section`

`

const PAContainer = styled.div`
    margin: 0 9.375rem 3rem 9.375rem;
    padding: 0 calc((100vw - 1440px) / 2) 0 calc((100vw - 1440px) / 2);
    color: #2A2C2B;


    @media screen and (max-width: 1020px){
      margin: 0 2rem 3rem 2rem;
      padding: 0;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem 3rem 1rem;
      padding: 0;
    }
`

const PAHeader = styled.h1`
    margin: 1.5em 0 .8em 0;
    text-transform: uppercase;
    font-size: 3em;
    font-weight: 800;
`

const PAParagraph = styled.p`
    width: 60%;

    @media screen and (max-width: 1020px){
      width: 80%;
    }

    @media screen and (max-width: 768px){
      width: 90%;
    }
`

const PASlice = styled.div`
    //border-left: 6vw solid #2A2C2B;
    //border-right: 24vw solid #2A2C2B;
    margin: 0 9.375rem 5rem 0;
    padding: 0;
    color: #2A2C2B;
    display: flex;

    @media screen and (max-width: 1020px){
      margin: 0 0 5rem 0;
      padding: 0;
    }

    @media screen and (max-width: 768px){
      margin: 0 0 5rem 0;
      padding: 0;
    }

`

const SliceLeft = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: calc(((100vw - 1440px) / 2) + 9.375rem);

    @media screen and (max-width: 1440px){
      width: 150px;
    }

    @media screen and (max-width: 1020px){
      width: 32px;
    }

    @media screen and (max-width: 768px){
      width: 16px;
    }
`

const SliceLeftBG = styled.div`
    width: 6vw;
    background-color: #2A2C2B;

    @media screen and (max-width: 1440px){
      width: 3vw;
    }

    @media screen and (max-width: 1020px){
      width: 1.5vw;
    }

    @media screen and (max-width: 768px){
      width: 1vw;
    }
`

const SliceMiddle = styled.div`
    width: 1140px;
    flex-shrink: 0;

    @media screen and (max-width: 1440px){
      width: 876px;
    }

    @media screen and (max-width: 1020px){
      width: 640px;
    }

    @media screen and (max-width: 768px){
      width: 380px;
    }
`

const SliceRight = styled.div`
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    width: calc(((100vw - 1440px) / 2) + 8.3rem);

    @media screen and (max-width: 1440px){
      width: calc(100vw - 1043px);
    }

    @media screen and (max-width: 1020px){
      width: calc(100vw - 689px);
    }

    @media screen and (max-width: 768px){
      width: calc(100vw - 413px);
    }
`

const SliceRightBG = styled.div`
    width: 24vw;
    background-color: #2A2C2B;

    @media screen and (max-width: 1440px){
      width: 20vw;
    }

    @media screen and (max-width: 1020px){
      width: 40vw;
    }

    @media screen and (max-width: 768px){
      width: 50vw;
    }
`

const Form = styled.form`

`

const FormHeader = styled.h2`
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: .2em;

    @media screen and (max-width: 1020px){
      padding-right: 2rem;
    }
    
`

const FormDesc = styled.p`
    margin-bottom: 2em;
    
    @media screen and (max-width: 1020px){
      padding-right: 2rem;
    }
`

const FormGroupCheck = styled(FormGroup)`

`

const FormSelectTitle= styled(FormLabel)`

`

const FormCheck = styled(Checkbox)`
    
`

const FormRadio = styled(Radio)`

`

const FormTextField = styled(TextField)`


`

const FormInputButton = styled(Button)`
    margin: 2em 0;
    padding: 1em 2em;
`